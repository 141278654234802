import { useCallback, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useTreasury } from 'contracts/treasury/hooks/use-treasury'
import { useGetSmartContract } from 'shared/hooks/use-smart-contracts'
import { handleCatchAction } from 'shared/utils/handle-catch-action'
import { showNotificationToast } from 'shared/utils/notify'
import { parseUnits } from 'viem'
import { activeBalanceSwitchTypeVar } from '../store/switch-types'
import { ApprovedBalanceSwitchTypes } from '../types'

/* eslint-disable-next-line max-statements */
export const useDepositSubmit = () => {
  const [loading, setLoading] = useState(false)
  const { smartContractAddress, smartContractVersion } =
    useGetSmartContract('Treasury')

  const { smartContractAddress: usdcAddress } = useGetSmartContract('USDC')
  const { smartContractAddress: xyroTokenAddress } =
    useGetSmartContract('XyroToken')

  const treasury = useTreasury(smartContractAddress, smartContractVersion)

  const activeBalanceType = useReactiveVar(activeBalanceSwitchTypeVar)

  const token =
    activeBalanceType === ApprovedBalanceSwitchTypes.Tether ?
      usdcAddress
    : xyroTokenAddress
  const decimals =
    activeBalanceType === ApprovedBalanceSwitchTypes.Tether ? 6 : 18

  const handleDeposit = useCallback(
    async (amount: string) => {
      if (!treasury) return

      setLoading(true)

      const { depositAmount } = treasury

      try {
        await depositAmount(parseUnits(amount, decimals), token)
        showNotificationToast({
          title: `${amount}USDT deposited`,
          type: 'success'
        })
      } catch (err) {
        handleCatchAction(err)
      } finally {
        setLoading(false)
      }
    },
    [treasury, decimals, token]
  )

  return { handleDeposit, loading }
}
