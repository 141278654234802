import { useRef } from 'react'
import classNames from 'classnames'
import { useChartInit } from '../hooks/use-chart-init'

const PriceChart: React.FC = () => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null)

  useChartInit(chartContainerRef)

  return (
    <div
      ref={chartContainerRef}
      style={{ height: 'calc(90vh - 80px)' }}
      className={classNames('TVChartContainer')}
    />
  )
}

export default PriceChart
