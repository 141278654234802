import { FC, ReactElement } from 'react'
import { Flex, Text } from '@radix-ui/themes'
import styles from '../approved-balance.module.scss'

interface Props {
  amount: number
  icon: ReactElement
  name: string
}

export const ApprovedBalanceRakebackGame: FC<Props> = ({
  amount,
  icon,
  name
}) => (
  <Flex
    justify={'between'}
    className={styles.approvedBalanceRakebackGame}
  >
    <Flex
      gap={'1'}
      width={'100%'}
      align={'center'}
    >
      {icon}

      <Text>{name}</Text>
    </Flex>

    <Text
      weight={'bold'}
      className={'color-white'}
    >
      {amount}&nbsp;USDT
    </Text>
  </Flex>
)
