import { gql } from '__generated__'

export const GET_RAKEBACK_SUMMARY = gql(`
  query getRakebackSummary($userId: String!) {
    getRakebackSummary(userId: $userId) {
      total
      bullseye
      setup
      updown
    }
  }
`)
