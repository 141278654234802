import { useQuery, useReactiveVar } from '@apollo/client'
import { GetRakebackSummaryQuery } from '__generated__/graphql'
import { GET_RAKEBACK_SUMMARY } from 'api/balance/get-rakeback-summary'
import { userVar } from 'shared/store/user'
import { DEFAULT_RAKEBACK_VALUES } from '../constants'

export const useLoadRakeback = () => {
  const user = useReactiveVar(userVar)

  const { data, loading, refetch } = useQuery<GetRakebackSummaryQuery>(
    GET_RAKEBACK_SUMMARY,
    {
      variables: {
        userId: user?.id
      },
      skip: !user?.id
    }
  )

  const result =
    data?.getRakebackSummary ? data.getRakebackSummary : DEFAULT_RAKEBACK_VALUES

  return {
    data: result,
    loading,
    refetch
  }
}
