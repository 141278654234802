/* eslint-disable max-statements */
import { useReactiveVar } from '@apollo/client'
import { Flex, Text } from '@radix-ui/themes'
import { DataTestIDs } from 'shared/constants'
import { useWallet } from 'shared/hooks/use-wallet'
import { WalletIcon } from 'shared/icons'
import { balanceVar } from 'shared/store/balance-store'
import { userVar } from 'shared/store/user'
import { formatBalance } from '../format-balance'
import { isOpenAllowanceDialogVar } from '../store/dialogs'
import { ApprovedBalanceContent } from './approved-balance-content'
import styles from '../approved-balance.module.scss'

export const ApprovedBalance = () => {
  const user = useReactiveVar(userVar)
  const { isReady } = useWallet()

  const balance = useReactiveVar(balanceVar)

  const onClickOpenDialog = () => {
    isOpenAllowanceDialogVar(true)
  }

  const fullFormattedBalance = `${formatBalance(balance.usdtBalance + balance.treasuryDeposit)} USDT`

  if (!user || !isReady) return null

  return (
    <>
      <Flex
        direction={'column'}
        className={styles.approvePopoverTrigger}
        onClick={onClickOpenDialog}
        data-testid={DataTestIDs.buttonApprovedBalanceHeader}
        p={'2'}
      >
        <Text
          className='color-white'
          size={'1'}
          weight={'medium'}
        >
          Balance:
        </Text>

        <Flex
          justify={'center'}
          align={'center'}
          gap={'1'}
        >
          <WalletIcon />

          <Text
            className={styles.balanceValue}
            data-testid={DataTestIDs.balanceValueHeader}
          >
            {fullFormattedBalance}
          </Text>
        </Flex>
      </Flex>

      <ApprovedBalanceContent />
    </>
  )
}
