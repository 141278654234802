/* eslint-disable max-params, max-statements, max-depth */

import {
  AssetPriceChangedSubscription,
  AssetPriceChangedSubscriptionVariables
} from '__generated__/graphql'
import { SUBSCRIPTION_ASSET_PRICE_CHANGED } from 'api/prices/subscription-asset-price-changed'
import { apolloClient } from 'app/apollo-client'
import { Bar, ResolutionString, SubscribeBarsCallback } from '../tradingview'
import { LibrarySymbolInfo } from '../tradingview/charting_library/datafeed-api'
import { SubscriptionItem } from '../types'

const channelToSubscription = new Map<string | undefined, SubscriptionItem>()

function getNextDailyBarTime(barTime?: number) {
  if (!barTime) {
    return new Date().setHours(0, 0, 0, 0)
  }

  const date = new Date(barTime)
  date.setMinutes(date.getMinutes() + 1)
  return date.getTime()
}

const handleStreamingData = (
  data: AssetPriceChangedSubscription['assetPriceChanged']
) => {
  const {
    assetId: channelString,
    formattedValue: tradePrice,
    timestamp: tradeTime
  } = data

  const subscriptionItem = channelToSubscription.get(channelString)

  if (!subscriptionItem) {
    return
  }

  const lastDailyBar = subscriptionItem.lastDailyBar

  if (!lastDailyBar) {
    return
  }

  const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time)

  let bar: Bar

  if (tradeTime >= nextDailyBarTime) {
    bar = {
      time: nextDailyBarTime,
      open: tradePrice,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice
    }
  } else {
    bar = {
      ...lastDailyBar,
      high: Math.max(lastDailyBar?.high || 0, tradePrice),
      low: Math.min(lastDailyBar?.low || 0, tradePrice),
      close: tradePrice
    }
  }

  subscriptionItem.lastDailyBar = bar

  subscriptionItem.handlers.forEach(handler => handler.callback(bar))
  channelToSubscription.set(channelString, subscriptionItem)
}

export const subscribeOnStream = (
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  onRealtimeCallback: SubscribeBarsCallback,
  subscriberUID: string,
  onResetCacheNeededCallback: () => void,
  lastDailyBar?: Bar
) => {
  const channelString = symbolInfo.ticker

  let subscriptionItem = channelToSubscription.get(channelString)

  if (!subscriptionItem) {
    subscriptionItem = {
      resolution,
      lastDailyBar,
      handlers: [],
      apolloSubscription: null
    }

    const apolloSubscription = apolloClient
      .subscribe<
        AssetPriceChangedSubscription,
        AssetPriceChangedSubscriptionVariables
      >({
        query: SUBSCRIPTION_ASSET_PRICE_CHANGED,
        variables: { assetId: channelString || '' }
      })
      .subscribe({
        next({ data }) {
          if (data && data.assetPriceChanged) {
            handleStreamingData(data.assetPriceChanged)
          }
        },
        error(err) {
          console.error(
            `[stream] Apollo subscription error for ${channelString}:`,
            err
          )
        }
      })

    subscriptionItem.apolloSubscription = apolloSubscription
  }

  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback
  }
  subscriptionItem.handlers.push(handler)
  subscriptionItem.lastDailyBar = lastDailyBar

  channelToSubscription.set(channelString, subscriptionItem)
}

export const unsubscribeFromStream = (subscriberUID: string) => {
  for (const [
    channelString,
    subscriptionItem
  ] of channelToSubscription.entries()) {
    const handlerIndex = subscriptionItem.handlers.findIndex(
      handler => handler.id === subscriberUID
    )

    if (handlerIndex !== -1) {
      subscriptionItem.handlers.splice(handlerIndex, 1)

      if (subscriptionItem.handlers.length === 0) {
        if (subscriptionItem.apolloSubscription) {
          subscriptionItem.apolloSubscription.unsubscribe()
        }

        channelToSubscription.delete(channelString)
      } else {
        channelToSubscription.set(channelString, subscriptionItem)
      }

      break
    }
  }
}
