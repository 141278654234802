import { useEffect, useRef } from 'react'
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  ResolutionString,
  widget
} from '../tradingview/charting_library'
import { UDFCompatibleDatafeed } from '../tradingview/datafeeds/udf/src/udf-compatible-datafeed'
import datafeed from '../utils/datafeed'

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol']
  interval: ChartingLibraryWidgetOptions['interval']

  // NOTE: no trailing slash is expected in feed URL
  datafeedUrl: UDFCompatibleDatafeed
  libraryPath: ChartingLibraryWidgetOptions['library_path']
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']
  clientId: ChartingLibraryWidgetOptions['client_id']
  userId: ChartingLibraryWidgetOptions['user_id']
  fullscreen: ChartingLibraryWidgetOptions['fullscreen']
  autosize: ChartingLibraryWidgetOptions['autosize']
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides']
  container: ChartingLibraryWidgetOptions['container']
}

export const useChartInit = (
  chartContainerRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const chartInstanceRef = useRef<IChartingLibraryWidget | null>(null)

  const defaultProps: Omit<
    ChartContainerProps,
    'container' | 'clientId' | 'userId' | 'chartsStorageUrl'
  > = {
    symbol: 'ETH',
    interval: '1' as ResolutionString,
    datafeedUrl: datafeed as UDFCompatibleDatafeed,
    libraryPath: '/tradingview/charting_library/',
    chartsStorageApiVersion: '1.1',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {}
  }

  useEffect(() => {
    const isChartAlreadyCreated = chartInstanceRef.current !== null
    const chartContainer = chartContainerRef?.current

    if (isChartAlreadyCreated || !chartContainer) return

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      // NOTE: no trailing slash is expected in feed URL
      datafeed: defaultProps.datafeedUrl,
      interval:
        defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
      container: chartContainerRef.current as HTMLDivElement,
      library_path: defaultProps.libraryPath as string,
      theme: 'dark',
      locale: 'en',
      debug: true,
      disabled_features: ['use_localstorage_for_settings'],
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides
    }

    const chartWidget = new widget(widgetOptions)

    chartWidget.onChartReady(() => {
      chartInstanceRef.current = chartWidget
    })
  }, [chartContainerRef])

  return {
    chartInstanceRef,
    chartContainerRef
  }
}
