import { useBlockChainBalance } from 'features/approved-balance/hooks/use-blockchain-balance'
import { useLoadingOverlay } from './use-loading-overlay'
import { useOffline } from './use-offline'
import { useOnPageChangeNavigateTop } from './use-on-page-change-navigate-top'
import { useSmartContracts } from './use-smart-contracts'
import { useVerifyUser } from './use-verify-user'

export const useAppHookParent = () => {
  useVerifyUser()
  useOffline()
  // TODO: Uncomment after TGE
  // useUserMetadata()
  useLoadingOverlay()
  useBlockChainBalance()
  useSmartContracts()
  useOnPageChangeNavigateTop()
}
