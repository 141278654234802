import { Flex } from '@radix-ui/themes'
import cn from 'classnames'
import { DataTestIDs } from 'shared/constants'
import { useResponsive } from 'shared/hooks/use-responsive'
import { RAKEBACK_GAMES_MAP } from '../constants'
import { useClaimRakeback } from '../hooks/use-claim-rakeback'
import { useLoadRakeback } from '../hooks/use-load-rakeback'
import { ApprovedBalanceRakebackClaimButton } from './approved-balance-rakeback-claim-button'
import { ApprovedBalanceRakebackGame } from './approved-balance-rakeback-game'
import { ApprovedBalanceValue } from './approved-balance-value'
import styles from '../approved-balance.module.scss'

export const ApprovedBalanceRakeback = () => {
  const { data, refetch } = useLoadRakeback()

  const { handleButtonClick, loading, disabled } = useClaimRakeback(
    data.total,
    refetch
  )

  const [isMobile] = useResponsive(['xs'])

  return (
    <Flex
      gap={{ initial: '5', sm: '0' }}
      className={cn(styles.balanceWrap, styles.approvedBalanceRakebackWrap)}
      justify={'between'}
      p={'4'}
    >
      <Flex>
        <Flex
          direction={'column'}
          justify={'between'}
          gap={'7'}
        >
          <ApprovedBalanceValue
            title='rakeback'
            value={data.total}
            dataTestID={DataTestIDs.treasureUnclaimedRakebackValue}
          />

          {!isMobile && (
            <ApprovedBalanceRakebackClaimButton
              handleButtonClick={handleButtonClick}
              disabled={disabled}
              loading={loading}
            />
          )}
        </Flex>
      </Flex>

      <Flex
        direction={'column'}
        gap={'2'}
      >
        {RAKEBACK_GAMES_MAP.map(({ icon, name, dataSource }) => (
          <ApprovedBalanceRakebackGame
            key={name}
            icon={icon}
            name={name}
            amount={data[dataSource]}
          />
        ))}
      </Flex>

      {isMobile && (
        <ApprovedBalanceRakebackClaimButton
          handleButtonClick={handleButtonClick}
          disabled={disabled}
          loading={loading}
        />
      )}
    </Flex>
  )
}
