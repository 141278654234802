/* eslint-disable max-params, max-statements, max-depth, max-lines */

import { apolloClient } from 'app/apollo-client'
import { GET_BARS_QUERY } from '../list-candles'
import {
  Bar,
  ResolutionString,
  SubscribeBarsCallback
} from '../tradingview/charting_library'
import {
  DatafeedErrorCallback,
  HistoryCallback,
  LibrarySymbolInfo,
  OnReadyCallback,
  ResolveCallback
} from '../tradingview/charting_library/datafeed-api'
import { PeriodParamsWithOptionalCountback } from '../tradingview/datafeeds/udf/src/history-provider'
import { subscribeOnStream, unsubscribeFromStream } from './streaming'

const lastBarsCache = new Map<string | undefined, Bar>()

const datafeed = {
  onReady: (callback: OnReadyCallback) => {
    setTimeout(() => {
      callback({
        supported_resolutions: [
          '1',
          '5',
          '15',
          '30',
          '60',
          '1D',
          '1W',
          '1M'
        ] as ResolutionString[],
        supports_marks: false,
        supports_timescale_marks: false
      })
    }, 0)
  },
  resolveSymbol: (
    symbolName: string,
    onSymbolResolvedCallback: ResolveCallback,
    onResolveErrorCallback: DatafeedErrorCallback
  ) => {
    setTimeout(() => {
      onSymbolResolvedCallback({
        type: 'crypto',
        listed_exchange: 'XYRO',
        format: 'price',
        name: 'ETH',
        description: 'ETHEREUM / US DOLLAR',
        session: '24x7',
        ticker: 'ETH',
        has_intraday: true,
        has_daily: true,
        has_weekly_and_monthly: true,
        intraday_multipliers: [
          '1',
          '2',
          '5',
          '15',
          '30',
          '60',
          '120',
          '240',
          '360',
          '720'
        ],
        daily_multipliers: ['1'],
        weekly_multipliers: ['1'],
        monthly_multipliers: ['1'],
        timezone: 'Etc/UTC',
        minmov: 1,
        pricescale: 100000000,
        supported_resolutions: [
          '1',
          '2',
          '5',
          '15',
          '30',
          '60',
          '120',
          '240',
          '360',
          '720',
          'D',
          '1D',
          'W',
          '1W',
          'M',
          '1M'
        ] as ResolutionString[],
        exchange: 'XYRO'
      })
    }, 0)
  },

  getBars: (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParamsWithOptionalCountback,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: DatafeedErrorCallback
  ) => {
    const { from, to, firstDataRequest } = periodParams

    apolloClient
      .query({
        query: GET_BARS_QUERY,
        variables: {
          data: {
            assetId: symbolInfo.ticker as string,
            startDate: (from as number) * 1000,
            endDate: (to as number) * 1000,
            timeframe: 60
          }
        }
      })
      .then(response => {
        const bars: Bar[] = []
        const data = response.data.listCandles
        if (data && data.length > 0) {
          for (let i = data.length - 1; i >= 0; i--) {
            const bar = data[i]
            bars.push({
              time: bar.openTime,
              low: bar.low,
              high: bar.high,
              open: bar.open,
              close: bar.close
            })
          }
        }

        if (firstDataRequest && bars.length > 0) {
          lastBarsCache.set(symbolInfo.ticker, {
            ...bars[bars.length - 1]
          })
        }

        onHistoryCallback(bars, { noData: bars.length === 0 })
      })
      .catch(error => {
        onErrorCallback(error)
      })
  },

  subscribeBars: (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    subscriberUID: string,
    onResetCacheNeededCallback: () => void
  ) => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.ticker)
    )
  },

  unsubscribeBars: (subscriberUID: string) => {
    unsubscribeFromStream(subscriberUID)
  }
}

export default datafeed
